<template>
  <div class="navtop row-between mb20">
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane
        v-for="(item, index) in navList"
        :key="index"
        :label="item"
        :name="index.toString()"
      ></el-tab-pane>
    </el-tabs>
    <div class="top-r flex1 row-end">
      <slot name="right"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  props: {
    //   导航列表
    navList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {},
  data() {
    return {
      activeName: "0",
    };
  },
  computed: {},
  watch: {
    //   选项卡激活
    activeName(newVal) {
      this.$emit("changeCurrent", newVal);
    },
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.navtop {
  border-bottom: 1px solid $-color-primary;
  /deep/ .el-tabs {
    .el-tabs__header {
      border: none;
      margin-bottom: 0;
      .el-tabs__nav,
      .el-tabs__item {
        border: none;
      }
      .el-tabs__item {
        font-size: 20px;
        height: 46px;
        line-height: 46px;
        padding: 0 24px;
        &::after {
          content: "";
          width: 1px;
          height: 20px;
          background-color: $-color-border;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        &:hover {
          color: $-color-primary;
        }
        &.is-active {
          background-color: $-color-primary;
          color: white;
          &::after {
            display: none;
          }
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}
</style>
